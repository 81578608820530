import React, { useState } from 'react';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { TextField, Button, Typography } from '@material-ui/core';


function GeminiChat() {
  const genAI = new GoogleGenerativeAI('AIzaSyDiUEV0umquL8ab2vRqg-9d8LuJwqQWH7I');
  const [userInput, setUserInput] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(''); // New state for prompt


  const generateText = async (prompt) => {
    try {
      // For text-only input, use the gemini-pro model
      const model = genAI.getGenerativeModel({ model: "gemini-pro"});
      console.log("Prompt:", prompt); // For debugging

      const result = await model.generateContent(prompt);
      const response = await result.response;

      if (response.error) {
        console.error("Error:", response.error.message);
        throw new Error("Error generating text"); // Re-throw for component handling
      }

      const text = response.text();
      return text;
    } catch (error) {
      console.error('Error generating text:', error);
      return ""; // Handle errors gracefully (e.g., display an error message)
    }
  };

  const handleSend = async () => {
    setLoading(true);
    const newResponse = await generateText(userInput);
    setResponse(newResponse);
    setPrompt(userInput);
    setUserInput(""); // Clear input field after sending
    setLoading(false);
  };

  return (
    <div style={{ backgroundColor: 'transparent', height: "100vh", display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#fff', }}>
      <Typography variant="h4" style={{ paddingTop: 20, fontFamily: 'Teko', color: 'tan', fontSize: '36px',}}>
        Ask me anything...
      </Typography>

      
      <div style={{ display: 'flex', marginTop: 20, width: '80%',backgroundColor: 'transparent', color: '#fff',}}>
        <TextField
          label="Add your question"
          variant="outlined"
          value={userInput}
          onChange={(event) => setUserInput(event.target.value)}
          fullWidth
          style={{ marginRight: 20, padding: 0, backgroundColor: 'white' }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSend();
            }
          }}
        />
        <Button
          variant="contained"
          style={{ backgroundColor: '#977a2f', color: '#fff' }}
          disabled={loading}
          onClick={handleSend} // Add onClick event handler to call handleSend function
        >
          {loading ? 'Loading...' : 'Go'}
        </Button>
      </div>
      {response && (
        <div style={{ marginTop: 20, width: '80%' }}> {/* Set width to 80% for response section */}
          <Typography variant="body1">
          {/* ... (other elements) */}
    {prompt && (
      <div>
        You asked: {prompt}
      </div>
    )}
          </Typography>
          <Typography variant="body1">
            Rayco's response: {response}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default GeminiChat;