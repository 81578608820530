import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import project10 from "../images/oracle.png";
import project20 from "../images/zoom.png";
import project30 from "../images/pivotal.png";
import project1 from "../images/html-css-javascript-lg.jpg";
import project2 from "../images/html-css-javascript.jpg";
import project3 from "../images/javascript-fullstack.jpg";
import project6 from "../images/react.png";
import project7 from "../images/salesforce.jpg";
import project8 from "../images/fuse-t.jpg";
import project9 from "../images/datasheet.jpg";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "transparent",
    margin: theme.spacing(10),
    position: "static",
    top: "auto",
    left: "auto",
    transform: "none",
    width: "auto",
    textAlign: "center",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      margin: theme.spacing(2),
    },
  },
  cardCard: {
    margin: "50px 0",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row", 
      margin: "40px 0 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0 0 0",
    },
  },

  cardStyle: 
  { 
    display: "flex",
    padding: "50px", 
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      display: "block",
    },
  },
  cardMedia: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      minWidth: 290,
    },
  },
  cardContent: {
    width: "70%",
    textAlign: "left",
    verticalAlign: "tex-top",
    fontSize: "24px",
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      display: "inline-flex",
      padding: "14px", 
      width: "100%",
      minWidth: 290,

    },
    
  },
  customH1: {
    fontFamily: "Teko",
    color: "tan",
    fontSize: "48px",
    
    
  },
  customH2: {
    fontFamily: "Teko",
    margin: "0 30px 0 0",
    color: "tan",
    fontSize: "38px",
    display: "flex",
    flexDirection: "row", 
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px", 
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      minWidth: 290,
    },
  },
  customP: {
    margin: "0 0 50px 0",
    fontFamily: "Roboto",
    color: "#cdcdcd",
    fontSize: "18px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 10px 0",
    },
  },
  customP3: {
    fontFamily: "Roboto",
    color: "#666",
    fontSize: "18px",
    display: "flex",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px", 
      width: "100%",
      minWidth: 290,
    },
    
  },
}));

const projects = [
  {
    name: "Oracle",
    description: "This is my past work from Oracle that streamlines the customer experience while also empowering people with clear GenAI prompts to gain insights.",
    link: "https://www.figma.com/deck/f5jburQ5cEJsEkjCGWEtLY/Reiko-Yamamoto-Oracle?node-id=1-447&node-type=slide&t=iUtOwMuJkkIamzG0-1&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1",
    image: project10,
  },
  {
    name: "Zoom",
    description: "This is a example of the AI powered tools that makes users to request and receive an AI powered summary of content in a Team SMS thread for the optimized collaborations.",
    link: "https://www.figma.com/deck/DvdK1YgujDLZA80vamv4A8/Reiko-Yamamoto-Zoom?node-id=1-447&node-type=slide&t=mbxumVj9pmFE0Tmh-1&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1",
    image: project20,
  },
  {
    name: "Pivotal Life Science",
    description: "This is a GenAI sample work that makes users worldwide to perform due diligence by using GenAI to make investment decisions and manage their portfolios.",
    link: "https://www.figma.com/deck/OqYkR8ycwzAInSLuXSlB75/Reiko-Yamamoto-Pivotal?node-id=1-447&node-type=slide&t=75IJShrh2gyuphJQ-1&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1",
    image: project30,
  },
  {
    name: "DIRECTV",
    description: `DIRECTV is a streaming service offering live TV and on-demand content. To enhance the viewing experience, I designed with algorithms, tags, and metadata tailored to provide advanced personalization.`,
    image: project1,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vT_cH4tKpTf-u4qB3KJagr_bJPY9aBeDae_FDOTCqvYqca6awHu8zWbT-IRbaA7X840XjTOEX58BnYO/pub?start=false&loop=false&delayms=3000",
    
  },
  {
    name: "Electronic Arts",
    description: `The Interview Sidekick is a web app aimed at optimizing the hiring process. I led the end-to-end experience design, focusing on user-centric approaches. Additionally, I collaborated with developers to build the Angular frontend.`,
    image: project2,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vQx1_7tnZNxqjed6Y6JWybBoBQPgT9ATO7X4GVX3sLMY_N0-qsJ6OTH4O03i8KEvPbaFqLc6SrClq87/pub?start=false&loop=false&delayms=3000",
  },
  {
    name: "US Bank",
    description: `US Bank mobile app is a mobile banking app where I worked as a lead designer, and working independently and collaboratively with primary stakeholders, while ensuring seamless experience by delivering user centric solutions.`,
    image: project3,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vSUhi46ArC9BkrRW_LvKiH22GpPnCBPNHABHzwf9o5Do36uxnh6CxSJoDABNK_f7tKr-l1_ZQFEz-5L/pub?start=false&loop=false&delayms=3000",
  },
  {
    name: "Panasonic Automotive",
    description: `As lead UX designer for Panasonic Automotive's project management app, I deisnged it with Teams and Power Apps, design systems and design patterns to enhance collaboration by tackling challenges like email overload.`,
    image: project6,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vRlwRmBm3JuUdOqFIEA5KOyqTk2ZWOa_NOVLfl5p5StV7jIw-9dkUb1I5QzBPYBJEULtd21zMv3s73o/pub?start=false&loop=false&delayms=3000",
  },
  {
    name: "Salesforce",
    description: `I created a experience concept for Salesforce platform products including security enhancement and data mask. I quickly finished within a couple of week and got buy-in from the PM.`,
    image: project7,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vSkvj0_9c9VI0bRW1juBVDlnU9TIEBE-7SZ38c3pt5HQ0mz8y_dg6RFTluyy_JMvlPinRFPLkjthDPP/pub?start=false&loop=false&delayms=3000",
  },
  {
    name: "Mckesson",
    description: `As a lead designer I was responsible for Fuse Data Platform, Analytics Explorer and Business Advisor services that earned some key clients including Vanderbilt University Medical Center.`,
    image: project8,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vS-28Hh3zKFzQiZ0xiJK6aP48oQ2p44tm5Weh8A2htybmG2ejzT3eV0nlajUIfQ6K0ECW6OnrgcGl0k/pub?start=false&loop=false&delayms=3000",
  },
  {
    name: "Voltaiq",
    description: `I was a designer in team of one at this startup and this project led Voltaiq to gain a new accoount with Amazon 126. It also won the Frost & Sullivan 2019 North American New Product Innovation Award.`,
    image: project9,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vRvMt6sBrVrPE92GaLMSLxjkiLBW2Zr5E3deyMrSey7T9VdGB0ZHf2DInM6RPRNyUyMmcUjX0f-QKJk/pub?start=false&loop=false&delayms=3000",
  },
];

const HeaderAndCardBox = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <div style={{textAlign: 'left', margin: '30px 0 0 0'}}>
        <Typography variant="h1" className={classes.customH1}>
          Hi, my name is Rachel.
        </Typography>
        <Typography variant="body1" className={classes.customP}>
          I'm a GenAI product designer based in San Francisco.
        </Typography>
      </div>
      <Grid container justify="center">
        {projects.map((project, i) => (
          <Grid item xs={12} sm={10} md={12} key={i}>
            <Card className={classes.cardCard}>
              <CardActionArea href={project.link} target="_blank"  className={classes.cardStyle}>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  alt={project.name}
                  height="100%"
                  image={project.image}
                />
                <CardContent className={classes.cardContent}>
                <Typography variant="h2" className={classes.customH2}>
                    {project.name}
                  </Typography>
                  <Typography variant="body1" className={classes.customP3}>
                    {project.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HeaderAndCardBox;
