import React from "react";

import GeminiChat from "./GeminiChat";


const Contact = () => {
  return (
    
    <React.Fragment>
     <GeminiChat/>
    </React.Fragment>
  );
};

export default Contact;
