import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles({
  bottomNavContainer: {
    background: "#222",
    height: "55px",
    overflow: "hidden",
  },
  root: {
    "& .MuiSvgIcon-root": {
      fill: "#cdcdcd",
      "&:hover": {
        fill: "#fff",
        fontSize: "1.8rem",
      },
    },
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <BottomNavigation className={classes.bottomNavContainer}>
      <a href="http://www.linkedin.com/in/reikoyamamotocyberhedz">
        <BottomNavigationAction icon={<LinkedInIcon />} className={classes.root} />
      </a>
      <a href="https://github.com/cyberhedz">
        <BottomNavigationAction icon={<GitHubIcon />} className={classes.root} />
      </a>
      <a href="https://cyberhedz.com/reiko-yamamoto">
        <BottomNavigationAction icon={<EmailIcon />} className={classes.root} />
      </a>
    </BottomNavigation>
  );
};

export default Footer;
